const LocalStorage = {
  get: (itemName: string) => {
    const item = localStorage.getItem(itemName);
    const numPattern = new RegExp(/^\d+$/);
    const jsonPattern = new RegExp(/[\[{].*[\}\]]/);

    if (typeof item === "undefined" || item === null) {
      return null;
    }

    if (item !== null && jsonPattern.test(item)) {
      return JSON.parse(item);
    }
    if (item !== null && numPattern.test(item)) {
      return parseFloat(item);
    }
    return item;
  },

  set: (itemName: string, item: object | any) => {
    if (typeof item === "object") {
      localStorage.setItem(itemName, JSON.stringify(item));
    } else {
      localStorage.setItem(itemName, item);
    }
  },

  remove: (itemName: string) => {
    localStorage.removeItem(itemName);
  },

  removeMany: (items: string[]) => {
    items.forEach((item: string) => {
      localStorage.removeItem(item);
    });
  },

  removeAll: () => {
    localStorage.clear();
  },
};

export default LocalStorage;
