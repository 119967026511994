/**
 * Adds suffix ':load' to the action name which is used to show loader in the UI
 * refer LoaderSlice.ts
 * @param actionName - Name of the action
 *  */
export const getActionNameForLoader = (actionName: string) => {
  return `${actionName}:load`;
};

/**
 * adds success key to the response object based on the error key in the response
 * @param resp - Response from the API
 * @returns 
 */
export const getApiRespObj = (resp: any) => {
  return { success: resp?.error ? false : true, ...resp };
};