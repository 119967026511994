import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export interface IToast
// extends ToastProps
{
  variant: any;
  showCloseIcon: boolean;
  message: string;
  duration?: number; //in milliseconds
  handleClose?: () => void;

}

const initialState: IToast = {
  variant: "",
  showCloseIcon: true,
  message: "",
};

const toastSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorToast: (state, action) => {
      return { variant: "error", ...action.payload };
    },
    setWarningToast: (state, action) => {
      return { variant: "warning", ...action.payload };
    },
    setInfoToast: (state, action) => {
      return { variant: "info", ...action.payload };
    },
    setSuccessToast: (state, action) => {
      return { variant: "success", ...action.payload };
    },
    clearToastData: (state) => {
      return initialState;
    },
  },
});

export const { setErrorToast, setWarningToast, setInfoToast, setSuccessToast, clearToastData } = toastSlice.actions;

export default toastSlice.reducer;

export const useToastSelector = () => useSelector((state: RootState) => state.toast);