export enum MILESTONE_TYPE {
  LIST = "list",
  DELIVERY = "delivery",
  DRAWING = "drawing",
  TASK = "task",
}

export enum MILESTONE_COMPLETION_FLOW {
  MARK_AS_COMPLETE = "mark_as_complete",
  VIA_APPROVAL = "via_approval",
  BASED_ON_PENDING_QUANTITY = "pending_quantity",
}

export enum MILESTONE_APPROVAL_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}
