import { DynamicDataType, IUser } from "interfaces/Workspace.interface";

export const getUserName = (nameObj: IUser) => {
  return nameObj?.firstName ? (nameObj.firstName + (nameObj?.lastName ? " " + nameObj.lastName : "")) : nameObj?.companyName || "-";
};

export const getValueofDynamicKey = (dynamicData: DynamicDataType[], key: string) => {
  return dynamicData?.length && (dynamicData?.find((data: any) => data.key === key)?.value || "-");
};

export const convertToCSV = (data: any[]) => {
  const array = [Object.keys(data[0])].concat(data);
  return array.map(row => {
    return Object.values(row).map(value => {
      if (typeof value === 'string') {
        // Replace | with \n and ensure the value is quoted
        return `"${value.replace(/\|/g, '\n').replace(/"/g, '""')}"`;
      }
      return value;
    }).join(',');
  }).join('\n');
};

export const downloadCSV = (data: any, fileName: string) => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', `${fileName}.csv`);
  a.click();
};