export const AppConstants = {
  STORAGE_KEYS: {
    userCategory: "userCategory",
    redirectTo: "redirectTo", // used to redirect to the page after login
  },
};

export enum USER_CATEGORY {
  CLIENT = "client",
  VENDOR = "vendor",
}

export enum USER_TYPE_DB {
  CLIENT = "ClientUser",
  VENDOR = "VendorUser",
}

export const FILE_UPLOAD_FORM_DATA_KEY = "files[]";
