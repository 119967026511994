import { Action, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isLoading: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action: Action) => action.type.includes("/pending") && action.type.includes(":load"),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action: Action) => action.type.includes("/fulfilled") && action.type.includes(":load"),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action: Action) => action.type.includes("/rejected") && action.type.includes(":load"),
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;

export default loaderSlice.reducer;

export const useLoaderSelector = () => useSelector((state: RootState) => state.loader);
