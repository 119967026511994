import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Main from "../Main";
import { Loader, useToast } from "venwiz-ui";
import { useLoaderSelector } from "../store/slices/LoaderSlice";
import { useAppDispatch } from "../store/hooks";
import { clearToastData, useToastSelector } from "../store/slices/ToastSlice";
import { USER_CATEGORY } from "../constants/AppConstants";
import { AuthRoute } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";
import { ROUTES } from "../constants/Routes.const";

const Landing = React.lazy(() => import("../pages/Landing"));
const SignUp = React.lazy(() => import("../pages/SignUp"));
const Login = React.lazy(() => import("../pages/Login"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const ProjectDetails = React.lazy(() => import("../pages/ProjectDetails"));
const PODetails = React.lazy(() => import("../pages/PODetails"));
const WorkspaceSettings = React.lazy(() => import("../pages/WorkspaceSettings"));
const CategorySettings = React.lazy(() => import("../pages/CategorySettings"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const LineItemsListing = React.lazy(() => import("../pages/LineItemsListing"));

const LoaderComponent = () => {
  const { isLoading } = useLoaderSelector();
  return isLoading ? <Loader loaderType={"page"} /> : <></>;
};

const ToastComponent = () => {
  const toast = useToast();
  const error = useToastSelector();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error?.message) {
      toast?.open(error);
    }
    return () => {
      dispatch(clearToastData());
    };
  }, [error?.message]);
  return <></>;
};

const AppRoutes = (
  <Router>
    <Suspense fallback={<Loader loaderType={"page"} />}>
      <Main>
        <LoaderComponent />
        <ToastComponent />
        <Routes>
          {/* Auth Routes */}
          <Route element={<AuthRoute />}>
            <Route path={ROUTES.COMMON.LANDING} element={<Landing />} />
            <Route path="/:userCategory/login" element={<Login />} />
            <Route path="/:userCategory/signup" element={<SignUp />} />
            <Route path="/:userCategory/reset-password" element={<ResetPassword />} />
            <Route path="/:userCategory/forgot-password" element={<ForgotPassword />} />
          </Route>

          {/* Protected Routes for user categories */}
          <Route element={<PrivateRoute routeFor="" />}>
            <Route path={`${ROUTES.COMMON.PO_DETAILS}/:po`} element={<PODetails />} />
            <Route path={ROUTES.COMMON.ITEMS} element={<LineItemsListing />} />
          </Route>

          {/* Protected Routes for client users */}
          <Route element={<PrivateRoute routeFor={USER_CATEGORY.CLIENT} />}>
            <Route path={ROUTES.CLIENT.DASHBOARD} element={<Dashboard />} />
            <Route path={`${ROUTES.CLIENT.PROJECT}/:projectname`} element={<ProjectDetails />} />
            <Route path={ROUTES.CLIENT.WORKSPACE_SETTINGS} element={<WorkspaceSettings />} />
            <Route path={ROUTES.CLIENT.CATEGORY_SETTINGS} element={<CategorySettings />} />
          </Route>

          {/* Protected Routes for vendor users */}
          <Route element={<PrivateRoute routeFor={USER_CATEGORY.VENDOR} />}>
            <Route path={ROUTES.VENDOR.DASHBOARD} element={<ProjectDetails />} />
          </Route>

          {/* handle not defined route */}
          <Route path="*" element={<Navigate to={ROUTES.COMMON.LANDING} />} />
        </Routes>
      </Main>
    </Suspense>
  </Router>
);

export default AppRoutes;
