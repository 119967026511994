import { MILESTONE_APPROVAL_STATUS } from "../../constants/Milestone.const";
import { MILESTONE_STATUS_OPTIONS } from "../../constants/Statuses.const";
import { DropdownOptionType, IFormField } from "../../interfaces/Form.interface";
import { StylesProps } from "venwiz-ui";

export const PO_LISTING_VIEW_OPTIONS_VALUE = {
  MILESTONE: "milestone",
  COMPONENT: "component",
};

export const PO_LISTING_VIEW_OPTIONS = [
  { value: PO_LISTING_VIEW_OPTIONS_VALUE.MILESTONE, label: "Milestone view" },
  { value: PO_LISTING_VIEW_OPTIONS_VALUE.COMPONENT, label: "Component view" },
];

export const Initial_Line_Item_Form: IFormField[] = [
  {
    type: "text",
    name: "itemCode",
    placeholder: "Item Code",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Item-Code",
  },
  {
    type: "text",
    name: "orderQuantity",
    placeholder: "PO Quantity",
    mandatory: true,
    value: "",
    hasError: false,
    label: "PO Quantity",
  },
  {
    type: "text",
    name: "drawingNo",
    placeholder: "Drawing Number",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Drawing No",
  },
  {
    type: "text",
    name: "description",
    placeholder: "Item Description",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Description",
  },
];

export const Initial_Edit_Line_Item_Form: IFormField[] = [
  ...Initial_Line_Item_Form,
  {
    type: "text",
    name: "pendingQuantity",
    placeholder: "Pending Quantity",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Pending Qty",
  },
];

export const MILESTONE_APPROVAL_STATUS_OPTIONS: Partial<{ [key in MILESTONE_APPROVAL_STATUS]: DropdownOptionType }> = {
  [MILESTONE_APPROVAL_STATUS.APPROVED]: { value: MILESTONE_APPROVAL_STATUS.APPROVED, label: "Approve" },
  [MILESTONE_APPROVAL_STATUS.REJECTED]: { value: MILESTONE_APPROVAL_STATUS.REJECTED, label: "Reject" },
  // [MILESTONE_APPROVAL_STATUS.PENDING]: { value: MILESTONE_APPROVAL_STATUS.PENDING , label: "Pending" },
};

export const Initial_Milestone_Form_Obj: { [key: string]: IFormField } = {
  name: {
    type: "text",
    name: "name",
    placeholder: "Milestone Name",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Milestone Name",
    selectedOption: null,
    options: [],
    index: 0,
  },
  plannedDueDate: {
    type: "date",
    name: "plannedDueDate",
    placeholder: "Select Due Date",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Due Date",
    selectedOption: null,
    options: [],
    index: 1,
  },
  accountablePerson: {
    type: "autocomplete",
    name: "accountablePerson",
    placeholder: "Accountable Person",
    mandatory: false,
    value: "",
    hasError: false,
    options: [] as { value: string; label: string }[],
    label: "Accountable",
    selectedOption: null,
    index: 2,
  },
  responsiblePerson: {
    type: "autocomplete",
    name: "responsiblePerson",
    placeholder: "Responsible Person",
    mandatory: true,
    value: "",
    hasError: false,
    options: [] as { value: string; label: string }[],
    label: "Responsible",
    selectedOption: null,
    index: 3,
  },
};

export const Initial_Milestone_Form: IFormField[] = Object.values(Initial_Milestone_Form_Obj);

export const Initial_Edit_Milestone_Form_Obj: { [key: string]: IFormField } = {
  ...Initial_Milestone_Form_Obj,
  plannedStartDate: {
    type: "date",
    name: "plannedStartDate",
    placeholder: "Planned Start Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Planned",
  },
  forecastStartDate: {
    type: "date",
    name: "forecastStartDate",
    placeholder: "Forecast Start Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Forecasted",
  },
  actualStartDate: {
    type: "date",
    name: "actualStartDate",
    placeholder: "Actual Start Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Actual",
  },
  forecastDueDate: {
    type: "date",
    name: "forecastDueDate",
    placeholder: "Forecast End Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Forecasted",
  },
  completionDate: {
    type: "date",
    name: "completionDate",
    placeholder: "Select Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Completion",
    selectedOption: null,
    index: 4,
  },
  approvalDate: {
    type: "date",
    name: "approvalDate",
    placeholder: "Select Date",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Approval Date",
    selectedOption: null,
    index: 5,
  },
  status: {
    type: "autocomplete",
    name: "status",
    placeholder: "Select Status",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Status",
    selectedOption: null,
    options: Object.values(MILESTONE_STATUS_OPTIONS),
    index: 6,
  },
  approvalStatus: {
    type: "autocomplete",
    name: "approvalStatus",
    placeholder: "Select Status",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Approval Status",
    selectedOption: null,
    options: Object.values(MILESTONE_APPROVAL_STATUS_OPTIONS),
    disabled: false,
    index: 7,
  },
};

export const Initial_Edit_Milestone_Form: IFormField[] = Object.values(Initial_Edit_Milestone_Form_Obj);

export enum EDIT_MILESTONE_OPTIONS {
  MILESTONE = "Milestone details",
  COMPONENTS = "Component list",
  COMMENTS = "Comments",
}

/** **/

export const UPDATE_COMP_LIST_COLS_OBJ_HEAD_IN_PROGRESS = {
  component: {
    name: "Select all",
    key: "name",
    styles: {
      textAlign: "left",
      marginLeft: "6px",
    } as StylesProps,
  },
  received: {
    name: "Received",
    key: "recived",
    styles: {} as StylesProps,
  },
  quantity: {
    name: "Quantity",
    key: "quantity",
    styles: {} as StylesProps,
  },

  notReceived: {
    name: "Not Received",
    key: "notRecived",
    styles: {} as StylesProps,
  },
  inProgress: {
    name: "In Progress",
    key: "inProgress",
    styles: {} as StylesProps,
  },
  completed: {
    name: "Completed",
    key: "completed",
    styles: {} as StylesProps,
  },
};
export const UPDATE_COMP_LIST_COLS_OBJ_ROW = {
  component: {
    name: "Select all",
    key: "name",
    styles: {
      textAlign: "left",
      marginLeft: "6px",
      width: "100%",
    } as StylesProps,
  },
};
export const EDIT_MILESTONE_TABS = {
  inProgress: {
    name: "In Progress",
    key: "inProgress",
  },
  completed: {
    name: "Completed",
    key: "completed",
  },
};

export const UPDATE_COMP_LIST_COLS_OBJ_HEAD_COMPLETED = {
  component: {
    name: "Select all",
    key: "name",
    styles: {
      textAlign: "left",
      marginLeft: "6px",
      width: "100%",
    } as StylesProps,
  },

  quantity: {
    name: "Quantity",
    key: "quantity",
    styles: {
      marginLeft: "6px",
    } as StylesProps,
  },
};

export const UPDATE_COMP_LIST_COLS_HEAD_IN_PROGRESS = Object.values(UPDATE_COMP_LIST_COLS_OBJ_HEAD_IN_PROGRESS);
export const UPDATE_COMP_LIST_COLS_ROW = Object.values(UPDATE_COMP_LIST_COLS_OBJ_ROW);
export const UPDATE_COMP_LIST_COLS_HEAD_COMPLETED = Object.values(UPDATE_COMP_LIST_COLS_OBJ_HEAD_COMPLETED);

export const Initial_Create_Component_Form_Obj: { [key: string]: IFormField } = {
  name: {
    type: "text",
    name: "name",
    placeholder: "Enter Component Name",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Component Name",
  },
  componentNumber: {
    type: "text",
    name: "componentNumber",
    placeholder: "Enter Component No.",
    mandatory: false,
    value: "",
    hasError: false,
    label: "Component Number",
  },
  drawingNumber: {
    type: "text",
    name: "drawingNumber",
    placeholder: "Enter Drawing Number",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Drawing Number",
  },
  quantity: {
    type: "number",
    name: "quantity",
    placeholder: "Enter Quantity",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Quantity",
  },
  weightInKg: {
    type: "number",
    name: "weightInKg",
    placeholder: "Enter Total Weight",
    mandatory: true,
    value: "",
    hasError: false,
    label: "Total Weight (in Kg)",
  },
};

export const Initial_Create_Component_Form: IFormField[] = Object.values(Initial_Create_Component_Form_Obj);
/** **/

export const Comment_Supported_File_Type_Exts = [".jpg", ".jpeg", ".png", ".pdf"];
export const Unsupported_File_Type_Error = `Please upload only images or pdf file/s. Supported file types ${Comment_Supported_File_Type_Exts.join(
  ", "
)}`;
