import React, { useEffect } from "react";
import "./App.css";
import routes from "./routes/Routes.tsx";
import { createGlobalStyle } from "styled-components";
import { globalStyles } from "./Theme";
import { setAuthHeader } from "./shared/helpers/httpService.helper";
import { useAuthenticationSelector, setUserCategory } from "./store/slices/AuthenticationSlice";
import { useAppDispatch } from "./store/hooks";
import { AppConstants } from "./constants/AppConstants";
import { LocalStorage } from "./services";

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box; 
  }
  
  html {
    font-size:${(props) => props.theme.baseFontSize};
  }
  body {
    font-family:${(props) => props.theme.fontFamily};
    background-color:${(props) => props.theme.bodyBG};
    color: ${(props) => props.theme.baseFontColor};
    button {
      font-family:${(props) => props.theme.fontFamily};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    /* -moz-appearance: textfield; */
  }
  

  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 30%;
    background: #d5d9ec;
    border-radius: 10px; /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
    padding-bottom:60px;
  }
`;

function App() {
  const { isLoggedIn, loginData, userCategory } = useAuthenticationSelector();
  const dispatch = useAppDispatch();
  const { STORAGE_KEYS } = AppConstants;

  useEffect(() => {
    setAuthHeader(loginData.token);
  }, [isLoggedIn, loginData]);

  useEffect(() => {
    if (!userCategory) {
      const rememberedAs = LocalStorage.get(STORAGE_KEYS.userCategory);
      dispatch(setUserCategory(rememberedAs));
    }
  }, []);

  return (
    <div className="App">
      <GlobalStyle theme={globalStyles} />
      {routes}
    </div>
  );
}

export default App;
