import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getActionNameForLoader, getApiRespObj } from "../../shared/helpers/store.helper";
import ApiConfig from "../../constants/ApiConfigs.const";
import httpService from "../../services/HttpService";
import { setErrorToast } from "./ToastSlice";
import { ICreatePO, IPO, IProject, IUpdatePO } from "../../interfaces/Workspace.interface";
import { PURGE } from "redux-persist";

interface IProjectState {
  selectedProject: null | IProject;
  projectPOs: IPO[];
}

const initialState: IProjectState = {
  selectedProject: null,
  projectPOs: [],
};

export const getProjectDetails = createAsyncThunk(getActionNameForLoader("project/getProjectDetails"), async (projectId: string, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.get(`${ApiConfig.endpoints.get_project}/${projectId}`);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

export const getProjectPOs = createAsyncThunk(getActionNameForLoader("project/getProjectPOs"), async (projectId: string, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.get(`${ApiConfig.endpoints.get_project_pos}/${projectId}`);
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

export const createPO = createAsyncThunk(getActionNameForLoader("project/createPO"), async (payload: ICreatePO, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.post(ApiConfig.endpoints.create_po, payload);
    dispatch(getProjectPOs(payload.projectId));
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

export const updatePO = createAsyncThunk(getActionNameForLoader("project/updatePO"), async (payload: IUpdatePO, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.patch(ApiConfig.endpoints.update_po, payload);
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

export const deletePO = createAsyncThunk(getActionNameForLoader("project/deletePO"), async (poId: string, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.delete(`${ApiConfig.endpoints.delete_po}/${poId}`);
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

//vendor side
export const getVendorPOs = createAsyncThunk(getActionNameForLoader("project/getVendorPOs"), async (vendorId: string, { rejectWithValue, dispatch }) => {
  try {
    const response = await httpService.get(`${ApiConfig.endpoints.get_vendor_pos}/${vendorId}`);
    return response.data;
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    // upon logout
    builder.addCase(PURGE, () => initialState);

    builder.addCase(getProjectPOs.fulfilled, (state, action) => {
      state.projectPOs = action.payload.data;
    });

    builder.addCase(getVendorPOs.fulfilled, (state, action) => {
      state.projectPOs = action.payload.data;
    });

  }
});

export const { setSelectedProject } = projectSlice.actions;

export default projectSlice.reducer;

export const useProjectSelector = () => useSelector((state: RootState) => state.project);