import { useEffect } from "react";
import { RouteProps, useParams, Outlet } from "react-router-dom";
import { AppConstants, USER_CATEGORY } from "../constants/AppConstants";
import { useAppDispatch } from "../store/hooks";
import { setUserCategory } from "../store/slices/AuthenticationSlice";
import React from "react";
import { LocalStorage } from "../services";

export const AuthRoute = (props: RouteProps): React.ReactElement | null => {
  const param = useParams();
  const disptach = useAppDispatch();
  const { STORAGE_KEYS } = AppConstants;

  useEffect(() => {
    const userCategory = param?.userCategory
      ? param.userCategory === USER_CATEGORY.CLIENT || param.userCategory === USER_CATEGORY.VENDOR
        ? param.userCategory
        : ""
      : "";
    if (userCategory) {
      LocalStorage.set(STORAGE_KEYS.userCategory, userCategory);
      disptach(setUserCategory(userCategory));
    }
  }, []);

  return <Outlet />;
};
