import { USER_CATEGORY } from "./AppConstants";

export const ROUTES = {

  // client
  CLIENT: {
    DASHBOARD: "/dashboard",
    LOGIN: `/${USER_CATEGORY.CLIENT}/login`,
    SIGNUP: `/${USER_CATEGORY.CLIENT}/signup`,
    FORGOT_PASSWORD: `/${USER_CATEGORY.CLIENT}/forgot-password`,
    RESET_PASSWORD: `/${USER_CATEGORY.CLIENT}/reset-password`,
    PROJECT: "/project",
    WORKSPACE_SETTINGS: "/workspace-settings",
    CATEGORY_SETTINGS: "/category-settings"
  },

  // vendor
  VENDOR: {
    DASHBOARD: "/pos",
    LOGIN: `/${USER_CATEGORY.VENDOR}/login`,
    SIGNUP: `/${USER_CATEGORY.VENDOR}/signup`,
    FORGOT_PASSWORD: `/${USER_CATEGORY.VENDOR}/forgot-password`,
    RESET_PASSWORD: `/${USER_CATEGORY.VENDOR}/reset-password`,
  },

  // common
  COMMON: {
    LANDING: "/",
    PO_DETAILS: "/po",
    ITEMS: "/items",
  }

} as const;