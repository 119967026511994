import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiConfig from "constants/ApiConfigs.const";
import { useSelector } from "react-redux";
import httpService from "services/HttpService";
import { getActionNameForLoader, getApiRespObj } from "shared/helpers/store.helper";
import { RootState } from "store/store";
import { setErrorToast } from "./ToastSlice";

export const uploadFileForDataImport = createAsyncThunk(getActionNameForLoader("dataImport"), async (payload: any, { rejectWithValue, dispatch },) => {
  try {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("workspaceId", payload.workspaceId);
    const response = await httpService.post(ApiConfig.endpoints.data_import, formData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

export const uploadFileForLineItemImport = createAsyncThunk(getActionNameForLoader("dataImport"), async (payload: any, { rejectWithValue, dispatch },) => {
  try {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("poId", payload.poId);
    const response = await httpService.post(ApiConfig.endpoints.line_item_import, formData);
    return getApiRespObj(response.data);
  } catch (error: any) {
    dispatch(setErrorToast({ message: error?.response?.data?.message }));
    return rejectWithValue(getApiRespObj(error?.response?.data));
  }
});

const dataImportSlice = createSlice({
  name: "dataImport",
  initialState: {},
  reducers: {}
});


export default dataImportSlice.reducer;

export const useProjectSelector = () => useSelector((state: RootState) => state.dataImport);