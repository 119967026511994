export enum PROJECT_STATUS_KEYS {
  ON_GOING = "on_going",
  COMPLETE = "complete",
}

export const PROJECT_STATUS_OPTIONS = {
  [PROJECT_STATUS_KEYS.ON_GOING]: {
    value: "on_going",
    label: "Ongoing",
  },
  [PROJECT_STATUS_KEYS.COMPLETE]: {
    value: "complete",
    label: "Complete",
  },
};
export enum PO_AND_LINE_ITEM_STATUS_KEYS {
  ON_TRACK = "on_track",
  DELAYED = "delayed",
  COMPLETE = "complete",
}

export const PO_AND_LINE_ITEM_STATUS_OPTIONS = {
  [PO_AND_LINE_ITEM_STATUS_KEYS.ON_TRACK]: {
    value: "on_track",
    label: "On Track",
  },
  [PO_AND_LINE_ITEM_STATUS_KEYS.DELAYED]: {
    value: "delayed",
    label: "Delayed",
  },
  [PO_AND_LINE_ITEM_STATUS_KEYS.COMPLETE]: {
    value: "complete",
    label: "Completed",
  },
};

export enum DELIVERY_MILESTONE_STATUS_KEYS {
  NOT_STARTED = "not_started",
  DELAYED = "delayed",
  COMPLETE = "complete",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export enum DELIVERY_MILESTONE_STATUS_UI_LABELS {}

export const DELIVERY_MILESTONE_STATUS_OPTIONS_ACC = {
  [DELIVERY_MILESTONE_STATUS_KEYS.NOT_STARTED]: {
    value: "not_started",
    label: "Not Started",
  },
  [DELIVERY_MILESTONE_STATUS_KEYS.DELAYED]: {
    value: "delayed",
    label: "Delayed",
  },
  [DELIVERY_MILESTONE_STATUS_KEYS.COMPLETE]: {
    value: "complete",
    label: "Complete",
  },
  [DELIVERY_MILESTONE_STATUS_KEYS.APPROVED]: {
    value: "approved",
    label: "Approve",
  },
  [DELIVERY_MILESTONE_STATUS_KEYS.REJECTED]: {
    value: "rejected",
    label: "Rejected",
  },
};

export const DELIVERY_MILESTONE_STATUS_OPTIONS_RESP = {
  [DELIVERY_MILESTONE_STATUS_KEYS.NOT_STARTED]: {
    value: "not_started",
    label: "Not Started",
  },
  [DELIVERY_MILESTONE_STATUS_KEYS.COMPLETE]: {
    value: "complete",
    label: "Complete",
  },
};

export enum DRAWING_MILESTONE_STATUS_KEYS {
  SUBMITTED_FOR_APPROVAL = "Submitted for Approval",
  PENDING_SUBMISSION = "Pending Submission",
  APPROVED_WITH_COMMENTS = "Approved with Comments",
  REJECTED = "Rejected",
  APPROVED = "Approved",
  REOPENED = "Reopened for Submission",
  DRAWING_COMMENTED = "Drawing Commented",
}

export const DRAWING_MILESTONE_STATUS_OPTIONS_ACC = {
  [DRAWING_MILESTONE_STATUS_KEYS.SUBMITTED_FOR_APPROVAL]: {
    value: "Submitted for Approval",
    label: "Submitted for Approval",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.PENDING_SUBMISSION]: {
    value: "Pending Submission",
    label: "Pending Submission",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.APPROVED_WITH_COMMENTS]: {
    value: "Approved with Comments",
    label: "Approved with Comments",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.REJECTED]: {
    value: "Rejected",
    label: "Rejected",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.APPROVED]: {
    value: "Approved",
    label: "Approved",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.REOPENED]: {
    value: "Reopened for Submission",
    label: "Reopened for Submission",
  },
  [DRAWING_MILESTONE_STATUS_KEYS.DRAWING_COMMENTED]: {
    value: "Drawing Commented",
    label: "Drawing Commented",
  },
};

export const DRAWING_MILESTONE_STATUS_OPTIONS_RESP = {
  [DRAWING_MILESTONE_STATUS_KEYS.SUBMITTED_FOR_APPROVAL]: {
    value: "Submitted for Approval",
    label: "Submitted for Approval",
  },
};

export enum MILESTONE_STATUS_KEYS {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
}

export const MILESTONE_STATUS_OPTIONS = {
  [MILESTONE_STATUS_KEYS.NOT_STARTED]: {
    value: "not_started",
    label: "Not Started",
  },
  [MILESTONE_STATUS_KEYS.IN_PROGRESS]: {
    value: "in_progress",
    label: "In Progress",
  },
  [MILESTONE_STATUS_KEYS.COMPLETE]: {
    value: "complete",
    label: "Completed",
  },
};
